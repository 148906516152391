import { Injectable } from '@angular/core';
import { gql, Subscription } from 'apollo-angular';

const APPLICATION_UDF_SUBSCRIPTION = gql`
subscription udfEvents($applicationId: String){
    onApplicationUDFEvent( input:{ applicationId:$applicationId }) {
    data {
      description
    }
  }
}
`;

@Injectable({
  providedIn: 'root'
})
export class ApplicationUDFSubscriptionService extends Subscription {

  document = APPLICATION_UDF_SUBSCRIPTION;
}