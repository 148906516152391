import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Message } from '@intellio/shared/models';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root',
})
export class AppMessagesService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  getExternal(applicationId) {
    const externalPostUrl =
      '/api/applications/' + applicationId + '/posts/external';
    return this.get<Message[]>(externalPostUrl);
  }

  getMessages(type, applicationId) {
    const internalPostUrl =
      '/api/applications/' + applicationId + '/posts/' + type;
    return this.get<Message[]>(internalPostUrl);
  }

  getMessagesFromSearch(type: string, applicationId: string): any {
    const searchUrl =
      '/api/search/applications/' + applicationId + '/posts/' + type;
    return this.get<Message[]>(searchUrl);
  }

  getPostTemplates(applicationId, data) {
    const postTemplateUrl =
      '/api/applications/' +
      applicationId +
      '/posttemplates/' +
      '?statusChangeId=' +
      data;
    return this.get<Message[]>(postTemplateUrl);
  }

  getAppMessageStatus(appId, status) {
    const url = `/api/applications/${appId}/statuses/${status}`;
    return this.get<Message>(url);
  }

  postMessage(
    type,
    applicationId,
    message,
    attachments,
    notifyUsernames,
    muteNotification
  ) {
    const url = '/api/applications/' + applicationId + '/posts/' + type;
    const postData = {
      Body: message,
      FileNames: attachments || [],
      NotifyUsernames: notifyUsernames || [],
      MuteNotification: muteNotification,
    };
    return this.post(url, postData);
  }

  deletePost(applicationId, postId) {
    const deletePostUrl =
      '/api/applications/' + applicationId + '/posts/' + postId;
    return this.delete(deletePostUrl);
  }
}
