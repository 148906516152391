import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedMaterialModule } from '@intellio/shared/material';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APIInterceptor } from './api.interceptor';
import { LoadingBarInterceptor } from './loading-bar.interceptor';
// bug 66468
import { SharedContainerComponentsModule } from '@intellio/shared/container-components'; // eslint-disable-line
import { APIErrorInterceptor } from './api-error.interceptor';
@NgModule({
  imports: [
    CommonModule,
    SharedMaterialModule,
    SharedContainerComponentsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingBarInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIErrorInterceptor,
      multi: true,
    },
  ],
})
export class SharedServicesModule {}

export { ApplicationsService } from './applications.service';
export { FormlyService } from './formly.service';
export { AuthService } from './auth.service';
export { PermissionsRequiredGuard } from './permissions-required.guard';
export { AuthGuard } from './auth-guard.service';
export { UrlRewriteGuard } from './url-rewrite-guard.service';
export { LoadingBarService } from './loading-bar.service';
export { phoneNumberValidator } from './form-validators/phone-number.validator';
export { zipCodeValidator } from './form-validators/zip-code.validator';
export { emailValidator } from './form-validators/email.validator';
export { passwordValidator } from './form-validators/passwords-must-match.validator';
export { feinValidator } from './form-validators/fein.validator';
export { requireTrueValidator } from './form-validators/require-true.validator';
export { accountNumberValidator } from './form-validators/account-number.validator';
export { repeatValidator } from './form-validators/repeat.validator';
export { ThemeService } from './theme.service';
export { AppConfigService } from './app-config.service';
export { LocalStorageService } from './local-storage.service';
export { SessionStorageService } from './session-storage.service';
export { HelpService } from './help.service';
export { NotificationService } from './notification.service';
export { EngineeringService } from './engineering.service';
export { EngineeringModuleGuard } from './engineering-module.guard';
export { TimersModuleGuard } from './timers-module.guard';
export { PaymentModuleGuard } from './payment-module.guard';
export { SupplementalFormsModuleGuard } from './supplemental-forms-module.guard';
export { PresetsModuleGuard } from './presets-module.guard';
export { ModuleService } from './module.service';
export { PermissionService } from './permission.service';
export { AccountService } from './account.service';
export { InvertersService } from './inverters.service';
export { EnergySourcesService } from './energy-sources.service';
export { AutoLogoutService } from './auto-logout.service';
export { OutOfOfficePopup } from './outofoffice-popup.service';
export { TokenRefreshService } from './token-refresh.service';
export { RoleService } from './role.service';
export { LoginService } from './login.service';
export { RegisterService } from './register.service';
export { ResetService } from './reset.service';
export { ApplicationStatusChangeService } from './application-status-change.service';
export { ApplicationFilesService } from './application-files.service';
export { AnalyticsService } from './analytics.service';
export { AppHistoryService } from './app-history.service';
export { GeocodeService } from './geocode.service';
export { AppUserService } from './app-user.service';
export { AppUdrService } from './app-udr.service';
export { AreasService } from './areas.service';
export { PresetsService } from './presets.service';
export { AdminBrandingService } from './admin-branding.service';
export { ContextualHelpService } from './contextual-help.service';
export { NotificationsService } from './notifications.service';
export { SystemLogsService } from './syslogs.service';
export { BusinessRolesService } from './business-roles.service';
export { ChangesService } from './changes.service';
export { AppMessagesService } from './app-messages.service';
export { ApplicationService } from './application.service';
export { AppPaymentsService } from './app-payments.service';
export { AppAggregationService } from './app-aggregation.service';
export { ChangePrimaryUsersService } from './change-primary-users.service';
export { ChangeStatusService } from './change-status.service';
export { AppStatusTimersService } from './app-status-timers.service';
export { SideDrawerService } from './side-drawer.service';
export { TemplatesService } from './templates.service';
export { AppTasksService } from './app-tasks.service';
export { TasksService } from './tasks.service';
export { AutomationLogService } from './automation-log.service';
export { AdminConfigService } from './admin-config.service';
export { VanillaHttpService } from './vanilla-http.service';
export { ApplicationDocusignService } from './application-docusign.service';
export { TasksModuleGuard } from './tasks-module.guard';
export { TemplatesModuleGuard } from './templates-module.guard';
export { AppCategoryService } from './app-category.service';
export { PvmoduleService } from './pvmodule.service';
export { WebhookService } from './webhook.service';
export { EnergyStorageSystemsService } from './energy-storage-systems.service';
export { TenantModuleConfigService } from './tenant-module-config.service';
export { ScheduledNotificationService } from './scheduled-notification.service';
export { ApplicationStatusService } from './application-status.service';
export { EmailTemplateService } from './email-template.service';
export { ConfirmEmailService } from './confirm-email.service';
export { ApplicationSupplementalFormsService } from './application-supplemental-forms.service';
export { OutOfOfficeService } from './outofoffice.service';
export { ActionRequiredAlertsService } from './action-required-alerts.service';
export { ActionRequiredAlertRuleService } from './action-required-alert-rules.service';
export { PrintingService } from './printing.service';
export { MessagesService } from './messages.service';
export { BackgroundUdrRefreshService } from './background-udr-refresh.service';
export { BackgroundFileRepositoryService } from './background-file-repository.service';
export { AttributionsService } from './attributions.service';
export { ApplicationPaymentSubscriptionService } from './application-payment-subscription.service';
export { PVWattsAPIService } from './pv-watts-api.service';
export { FeatureManagementService } from './feature-management.service';
export { ProjectsService } from './projects.service';
export { ApplicationStatusSubscriptionService } from './application-status-subscription.service';
export { ApplicationUDFSubscriptionService } from './application-udf-subscription.service';