export class UserListRequestParams {
  search: string;
  skip: number;
  take: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  roles?: string[];
  isExternal?: boolean;
  isDeleted?: boolean;
  UserIsDeactivated?: boolean;
  emailConfirmed?: boolean;
  hasPassword?: boolean;
  acceptingNewApplications?: boolean;
}
