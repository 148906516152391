import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  Application,
  ApplicationTypeStatusBase,
  FileUploadWithMetadata,
  Task,
  TaskFile,
  TaskTemplate,
} from '@intellio/shared/models';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
/* eslint-disable @nx/enforce-module-boundaries  */
// bug 66468 all imports
import {
  AppTasksService,
  ApplicationFilesService,
  NotificationService,
  SideDrawerService,
} from '@intellio/shared/services';
import { ApplicationFacade } from '@intellio/shared/state-mgmt';
import { Observable, Subject, forkJoin, of, throwError } from 'rxjs';
import { catchError, debounceTime, take, takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { AddTaskAssignmentComponent } from '../add-task-assignment/add-task-assignment.component';
import { AppTaskDeleteComponent } from '../app-task-delete/app-task-delete.component';
import { AppTasksTemplateComponent } from '../app-tasks-template/app-tasks-template.component';
import { MatTableDataSource } from '@angular/material/table';
import { TaskFileDeleteComponent } from '../task-file-delete/task-file-delete.component';
import { TaskFilesUploaderComponent } from '../task-files-uploader/task-files-uploader.component';

@Component({
  // eslint-disable-next-line
  selector: 'itc-app-task-details',
  templateUrl: './app-task-details.component.html',
  styleUrls: ['./app-task-details.component.scss'],
})
export class AppTaskDetailsComponent implements OnInit {
  @Input() task: Task;
  @Input() taskId: number;
  @Input() statusList: ApplicationTypeStatusBase[];
  @Input() addNew: boolean;
  @Input() myTasksView: boolean;
  @Output() refreshData = new EventEmitter<void>();
  private destroyed$ = new Subject();
  upload = new EventEmitter();

  constructor(
    private sideDrawerService: SideDrawerService,
    private formBuilder: UntypedFormBuilder,
    private appTasksService: AppTasksService,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    private applicationFacade: ApplicationFacade,
    private matDialog: MatDialog,
    private applicationFilesService: ApplicationFilesService,
    private filesService: ApplicationFilesService
  ) {}
  formGroup: UntypedFormGroup;
  currentApplication: Application;
  addAssigneeDialogRef: MatDialogRef<AddTaskAssignmentComponent>;
  deleteTaskDialogRef: MatDialogRef<AppTaskDeleteComponent>;
  chooseTemplateDialogRef: MatDialogRef<AppTasksTemplateComponent>;
  loading = true;
  templates: TaskTemplate[];
  dataSource = new MatTableDataSource<TaskFile>([]);
  fileToUpload: any;
  fileHeaders: string[] = ['fileName', 'fileUploadDate', 'buttonOptions'];
  dialogIsOpen = false;
  dialogRef: MatDialogRef<TaskFilesUploaderComponent, any>;

  ngOnInit(): void {
    if (this.task == null) {
      this.appTasksService.getTask(this.taskId).subscribe((data) => {
        this.task = data.data;
        this.setupTaskDetails();
      });
    } else {
      this.setupTaskDetails();
    }
  }

  emitRefreshDataEvent() {
    setTimeout(() => {
      this.refreshData.emit();
    }, 750);
  }

  setupTaskDetails() {
    this.getLastModifiedDateTime();
    this.getInitials();
    const taskAssignments = cloneDeep(this.task.assignees);

    this.formGroup = this.formBuilder.group({
      dueDate: [this.task.dueDate],
      priority: [this.task.priority],
      applicationStatusId: [this.task.applicationStatusId],
      status: [this.task.status],
      description: [this.task.description],
      notes: [this.task.notes],
      displayName: [this.task.displayName, [Validators.required]],
      taskAssignments: [taskAssignments || [], [Validators.required]],
      blocksStatusChange: [this.task.blocksStatusChange],
    });

    this.dataSource.data = [];
    if (
      this.task?.attachedFiles != null &&
      this.task?.attachedFiles != undefined
    ) {
      this.dataSource.data.push(this.task.attachedFiles[0]);
    }

    if (
      this.task?.applicationId != null &&
      this.task?.applicationId != undefined &&
      !this.myTasksView // Only access templates if viewing from a single app instead of the tasks queue
    ) {
      this.applicationFacade.currentApplication$
        .pipe(take(1))
        .subscribe((data) => {
          this.currentApplication = data;
        });
      this.getTemplates();
    } else {
      this.loading = false;
    }
  }

  openFileRepository(externalId) {
    if (externalId && externalId.indexOf('/') !== -1) {
      var url =
        'https://exl1-wtutil.opentext.cloud/webtop/drl/objectId/{folderId}'.replace(
          '{folderId}',
          externalId.split('/')[1]
        );
      window.open(url, '_blank');
    }
  }

  deleteTask(task) {
    this.deleteTaskDialogRef = this.dialog.open(AppTaskDeleteComponent, {
      data: task,
    });
    this.deleteTaskDialogRef.afterClosed().subscribe(() => {
      this.emitRefreshDataEvent();
    });
  }

  addAssigneeDialog() {
    this.addAssigneeDialogRef = this.dialog.open(AddTaskAssignmentComponent);
    this.addAssigneeDialogRef.afterClosed().subscribe((data) => {
      if (data) {
        data?.addedUsers?.forEach((user) => {
          this.formGroup.controls['taskAssignments'].value.push(user);
        });
        data?.addedGroups?.forEach((group) => {
          this.formGroup.controls['taskAssignments'].value.push(group);
        });
        this.formGroup.controls['taskAssignments'].markAsDirty();
        if (this.formGroup.controls['taskAssignments'].value.length > 0) {
          this.formGroup.controls['taskAssignments'].updateValueAndValidity();
        }
      }
    });
  }

  addTemplateDialog() {
    this.chooseTemplateDialogRef = this.dialog.open(AppTasksTemplateComponent, {
      data: { appTemplates: this.templates },
    });
    this.chooseTemplateDialogRef.afterClosed().subscribe((data) => {
      if (data.currTemplate) {
        this.formGroup.controls['displayName'].setValue(
          data.currTemplate.displayName
        );

        this.formGroup.controls['status'].setValue(data.currTemplate.status);

        if (data.currTemplate.taskAssignments !== undefined) {
          data.currTemplate.taskAssignments.forEach((assignment) => {
            this.formGroup.controls['taskAssignments'].value.push(assignment);
          });
        }

        if (data.currTemplate.dueDate !== null) {
          this.formGroup.controls['dueDate'].setValue(
            data.currTemplate.dueDate
          );
        }

        this.formGroup.controls['blocksStatusChange'].setValue(
          data.currTemplate.blocksStatusChange
        );

        if (data.currTemplate.priority !== null) {
          this.formGroup.controls['priority'].setValue(
            data.currTemplate.priority
          );
        }

        this.formGroup.controls['description'].setValue(
          data.currTemplate.description
        );

        this.formGroup.controls['notes'].setValue(data.currTemplate.notes);
        this.formGroup.markAsDirty();
      }
    });
  }

  getTemplates() {
    this.loading = true;
    this.appTasksService
      .getTemplates(this.currentApplication?.typeId)
      .subscribe(
        (data) => {
          this.templates = data.data;
          this.loading = false;
        },
        (err: any) => {
          this.loading = false;
          this.notificationService.open(err.error.message, 'error');
          return throwError(err);
        }
      );
  }

  deleteAssignment(assignment) {
    if (assignment.user?.fullName != null) {
      this.formGroup.controls['taskAssignments'].setValue(
        this.formGroup.controls['taskAssignments'].value.filter(function (a) {
          return (a.firstName?.concat(a.lastName) ?? '') !== (assignment.firstName?.concat(assignment.lastName) ?? '');
        })
      );
    } else {
      this.formGroup.controls['taskAssignments'].setValue(
        this.formGroup.controls['taskAssignments'].value.filter(function (a) {
          return (a.groupName ?? '') !== (assignment.groupName ?? '');
        })
      );
    }
    this.formGroup.controls['taskAssignments'].markAsDirty();
  }

  updateTask() {
    this.loading = true;
    //delete task assignments

    let assignments = this.task.assignees;

    assignments.forEach((a) => {
      const taskAssignments = this.formGroup.controls['taskAssignments'].value;

      const groupIdMatch = taskAssignments.some(
        (ta) => ta.groupId === a.groupId
      );
      const userIdMatch = taskAssignments.some((ta) => ta.userId === a.userId);

      if (!groupIdMatch || !userIdMatch) {
        this.appTasksService.deleteTaskAssignment(a.id).subscribe(
          () => {
            this.notificationService.open('Assignment Deleted', 'success');
          },
          (err) => {
            this.notificationService.open(err.error.message, 'error');
            return throwError(err);
          }
        );
      }
    });

    //add task assignments
    this.formGroup.controls['taskAssignments'].value.map((a) => {
      if (assignments.filter((ta) => ta.userId === a.userId).length === 0) {
        this.appTasksService
          .createTaskAssignment(this.task.id, a.userId, a.groupId)
          .pipe(
            catchError((err: any) => {
              this.notificationService.open(err.error.message, 'error');

              return throwError(err);
            }),
            take(1),
            takeUntil(this.destroyed$)
          )
          .subscribe(() => {
            this.notificationService.open('Assignee Added', 'success');
          });
      }
    });

    //set the other attributes
    const dataToPut = {
      Status: this.formGroup.controls['status'].value,
      ApplicationStatusId: this.formGroup.controls['applicationStatusId'].value,
      Priority: this.formGroup.controls['priority'].value,
      DisplayName: this.formGroup.controls['displayName'].value,
      Description: this.formGroup.controls['description'].value,
      Notes: this.formGroup.controls['notes'].value,
      DueDate: this.formGroup.controls['dueDate'].value,
      BlocksStatusChange: this.formGroup.controls['blocksStatusChange'].value,
    };
    this.appTasksService.updateTask(this.task.id, dataToPut).subscribe(
      () => {
        if (this.fileToUpload != null) {
          this.completeFileUpload(this.task.id);
        } else {
          this.notificationService.open('Task Updated', 'success');
          this.loading = false;
          this.closeDrawer();
          this.emitRefreshDataEvent();
        }
      },
      (err: any) => {
        this.notificationService.open(err.error.message, 'error');

        return throwError(err);
      }
    );
  }

  createTask() {
    //create task
    const dataToPost = {
      Status: this.formGroup.controls['status'].value,
      ApplicationStatusId: this.myTasksView
        ? 'Draft'
        : this.formGroup.controls['applicationStatusId'].value,
      Priority: this.formGroup.controls['priority'].value,
      DisplayName: this.formGroup.controls['displayName'].value,
      Description: this.formGroup.controls['description'].value,
      Notes: this.formGroup.controls['notes'].value,
      DueDate: this.formGroup.controls['dueDate'].value,
      ApplicationId: this.myTasksView ? null : this.currentApplication.id,
      AutoCompleteType: 'Manual',
      BlocksStatusChange: this.formGroup.controls['blocksStatusChange'].value,
    };
    this.appTasksService.createTask(dataToPost).subscribe(
      (data) => {
        this.task.id = Number(data.data);
        //add task assignments
        this.formGroup.controls['taskAssignments'].value.map((a) => {
          this.appTasksService
            .createTaskAssignment(this.task.id, a.userId, a.groupId)
            .pipe(
              catchError((err: any) => {
                this.notificationService.open(err.error.message, 'error');

                return throwError(err);
              }),
              take(1),
              takeUntil(this.destroyed$)
            )
            // bug 66465
            // eslint-disable-next-line
            .subscribe(() => {
              this.notificationService.open('Assignee Added', 'success');
            });
        });
        this.notificationService.open('Task Created', 'success');
        if (this.fileToUpload != null) {
          this.completeFileUpload(this.task.id);
        }
        this.closeDrawer();
        this.emitRefreshDataEvent();
      },
      (err: any) => {
        this.notificationService.open(err.error.message, 'error');

        return throwError(err);
      }
    );
  }

  uploadFile(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (!inputElement.files || inputElement.files.length === 0) {
      return;
    }
    this.formGroup.markAsDirty();
    this.fileToUpload = inputElement.files[0];
    this.upload.emit(inputElement.files[0]);
  }

  clearInputValue(event: Event) {
    // This clears the value since the file selection is based on the "change" event.
    // Therefore we support the case where the user selects the same file over and over
    const inputElement = event.target as HTMLInputElement;
    inputElement.value = null;
  }

  decodeFileName(filename) {
    if (!filename) {
      return filename;
    }
    return decodeURIComponent(
      filename?.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')
    );
  }

  downloadFile(fileName: string) {
    this.filesService.downloadTaskFile(fileName, this.task.id.toString());
  }

  deleteTaskFile(file) {
    const dialogRef = this.matDialog.open(TaskFileDeleteComponent, {
      data: {
        taskFile: file,
        appId: this.currentApplication?.id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.type !== 'close') {
        this.closeDrawer();
      }
    });
  }

  closeDrawer() {
    this.sideDrawerService.closeExistingDialog();
  }

  completeFileUpload(taskId) {
    var fileMetadata = new FileUploadWithMetadata();
    fileMetadata.file = this.fileToUpload;
    fileMetadata.fileName = this.fileToUpload.name;
    fileMetadata.fileMimeType = this.fileToUpload.type;
    this.formGroup.markAllAsTouched();
    const results: Observable<any>[] = [];
    if (
      fileMetadata.forInternalUseOnly === undefined ||
      fileMetadata.forInternalUseOnly === null
    ) {
      fileMetadata.forInternalUseOnly = true;
    }
    fileMetadata.fileTypeId = 'Other';
    results.push(
      this.applicationFilesService.uploadTaskFile(taskId, fileMetadata).pipe(
        catchError((error: any) => {
          return of(false);
        })
      )
    );
    forkJoin(results).subscribe(
      () => {
        this.notificationService.open('Task Updated', 'success');
        this.loading = false;
        this.closeDrawer();
        this.emitRefreshDataEvent();
      },
      (err) => {
        this.notificationService.open('Error uploading file to task', 'error');
        this.loading = false;
        this.closeDrawer();
        this.emitRefreshDataEvent();
      }
    );
  }

  getInitials() {
    this.task.applicationTaskAssignments?.forEach((ta) => {
      ta.initials = ta.user
        ? ta.firstName?.substring(0, 1) + ta.lastName?.substring(0, 1)
        : ta.groupName?.substring(0, 1);
    });
  }

  getLastModifiedDateTime() {
    this.task.lastUpdatedDateTime =
      this.task.modifiedDateTime !== null
        ? this.task.modifiedDateTime
        : this.task.createdDateTime;
  }
}
